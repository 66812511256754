import classNames from 'classnames';
import { Fragment } from 'react';
import ReactPlaceholder from 'react-placeholder';
import Responsive from 'src/components/Responsive';
import { CatalogActiveFiltersList, ContentSortDropdown } from 'src/modules/filters';
import { CatalogSortingProps } from 'src/modules/filters/hooks/useCatalogSorting';
import useUpdateQueryParameter from 'src/modules/filters/hooks/useUpdateQueryParameter';
import { ActiveFilter } from 'src/modules/filters/utils/getActiveFiltersObjects';
import useLocalize from 'src/services/localize/useLocalize';
import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme';
import { LoadMoreAndPaginationProps } from '../../hooks/useLoadMoreAndPaginationTypes';
import CatalogProductTile from '../CatalogProductTile/CatalogProductTile';
import GoodsNumber from '../GoodsNumber/GoodsNumber';
import Pagination from '../Pagination/Pagination';
import ProductTilePlaceholder from '../ProductTilePlaceholder/ProductTilePlaceholder';
import getStyles from './ProductBox.styles';

export interface ProductBoxProps {
  countAvailable: number;
  activeFilters: ActiveFilter[];
  sortingProps: CatalogSortingProps;
  loadMoreAndPaginationProps: LoadMoreAndPaginationProps;
}

const ProductBox = (props: ProductBoxProps) => {
  const { countAvailable, activeFilters, sortingProps, loadMoreAndPaginationProps } = props;

  const { selectedSortItem, sortList, onSort } = sortingProps;

  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  const {
    loadMoreIsFetching,
    loadMoreIsVisible,
    paginationIsVisible,
    products,
    paginationProps,
    handleLoadMore,
  } = loadMoreAndPaginationProps;

  const { clearAllQueryParameters, updateQueryParameter } = useUpdateQueryParameter();

  return (
    <Fragment>
      <div className="ProductBox" data-marker='Products'>
        <div className="ProductBox__head">
          <div className="ProductBox__count" data-marker='Goods Number'>
            <GoodsNumber number={countAvailable} />
          </div>
          <Responsive displayOn="desktop">
            <ContentSortDropdown
              label={`${localize('filters.what-to-show')}:`}
              items={sortList}
              onChange={onSort}
              active={selectedSortItem}
            />
          </Responsive>
        </div>

        <CatalogActiveFiltersList
          activeFilters={activeFilters}
          onClearAll={clearAllQueryParameters}
          onDeleteItem={updateQueryParameter}
        />

        <div className="ProductBox__list" data-marker="Products List">
          {products.map((product) => (
            <div
              key={`${product.ean}-list-item`}
              className="ProductBox__listItem"
            >
              <CatalogProductTile
                product={product}
                place="category"
              />
            </div>
          ))}
        </div>

        {loadMoreIsVisible && (
          <ReactPlaceholder
            ready={!loadMoreIsFetching}
            customPlaceholder={<ProductTilePlaceholder repeat={4} />}
          >
            <div className='ProductBox__loadMore'>
              <Button
                variant='primary'
                onClick={handleLoadMore}
                dataMarker='Load More'
              >
                {localize('show_more')}
              </Button>
            </div>
          </ReactPlaceholder>
        )}

        {paginationIsVisible && (
          <div className={classNames("ProductBox__pagination", {
            ProductBox__pagination_withMargin: !loadMoreIsVisible,
          })}>
            <Pagination paginationOptions={paginationProps} />
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default ProductBox;
