import Image from "next/image";
import { useMediaQuery } from "react-responsive";
import useLocalize from "src/services/localize/useLocalize";
import { desktop } from "src/styles/media";
import Button from "src/ui-kit/Button";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";
import Price from "src/UIKitOld/Price/Price";
import { RetailInfo } from "../../types";
import CartCountIcon from "../CartCountIcon/CartCountIcon";
import RetailTimeWindow from "../RetailTimeWindow/RetailTimeWindow";

interface RetailInfoRowCardProps {
  retailInfo: RetailInfo;
  onClick: () => void;
}

const RetailInfoRowCard = (props: RetailInfoRowCardProps) => {
  const {
    retailInfo: {
      linkUrl,
      logoUrl,
      id,
      chainName,
      timeWindowText,
      timeWindowHasError,
      timeWindowTooltip,
      cartCount,
      price,
    },
    onClick,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const isDesktop = useMediaQuery({ query: desktop });

  const timeWindowDataMarker = timeWindowHasError ? 'Time slot Error' : 'Time slot';
  const tooltipId = `${id}_tooltip`;

  return (
    <StylishBox
      element="a"
      elementProps={{
        target: '_blank',
        rel: 'noreferrer',
        href: linkUrl,
        onClick,
      }}
      dataMarker={chainName}
      sbs={{
        display: {
          general: 'block',
          desktopTablet: 'flex',
        },
        alignItems: 'center',
        backgroundColor: theme.base.color.white,
        borderRadius: theme.base.borderRadii.v1,
        p: {
          desktop: '24px',
          tablet: '20px 16px',
          phone: '16px 12px',
        },
        cursor: 'default',
        mb: '12px',
        position: 'relative',
      }}
    >
      <StylishBox
        sbs={{
          width: '125px',
        }}
      >
        <Image
          src={logoUrl}
          alt={chainName}
          width={125}
          height={39}
        />
      </StylishBox>
      <StylishBox
        sbs={{
          width: {
            desktop: '105px',
            tablet: '61px',
          },
          pl: {
            desktop: '16px',
            tablet: '12px',
          },
          pr: {
            tablet: '12px',
          },
          position: {
            phone: 'absolute',
          },
          top: '23px',
          right: '21px',
        }}

      >
        {!!cartCount && (
          <CartCountIcon
            count={cartCount}
          />
        )}
      </StylishBox>


      {timeWindowText && (
        <StylishBox
          sbs={{
            mt: {
              phone: '8px',
            },
            mb: {
              phone: '4px',
            },
          }}
        >
          <RetailTimeWindow
            timeWindowText={timeWindowText}
            dataMarkerValue={timeWindowDataMarker}
            id={tooltipId}
            timeWindowTooltip={timeWindowTooltip}
          />
        </StylishBox>
      )}

      <StylishBox
        sbs={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: {
            phone: 'flex-end',
          },
          ml: 'auto',
          width: {
            phone: '100%',
          },
        }}
      >
        {!!price && (
          <Typography
            sbs={{
              mr: {
                general: '26px',
                desktop: '56px',
                phone: 'auto',
              },
              textAlign: 'right',
            }}
            variant='text3'
          >
            <Price price={price} />
          </Typography>
        )}

        <StylishBox
          sbs={{
            width: {
              desktop: '112px',
            },
          }}
        >
          <Button
            variant={isDesktop ? "secondary" : 'ghost'}
            width="100%"
          >
            {localize('general.buy')}
          </Button>
        </StylishBox>
      </StylishBox>
    </StylishBox>
  );
};

export default RetailInfoRowCard;
