import { DeliveryType, UserDeliveryType } from "src/modules/delivery/types";


export const getDeliveryTypeIsNP = (type: DeliveryType | undefined) => {
  return type === 'nova_poshta' || type === 'nova_poshta_address';
};

export const getNPSettlementIdFromUserDelivery = (userDelivery: UserDeliveryType) => {
  if(getDeliveryTypeIsNP(userDelivery?.type)) {
    return userDelivery.settlement.id;
  }
};
