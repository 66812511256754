import { Theme, ThemeBaseBorderRadiiCSS, ThemeBaseBorderWidthAndStyleCSS, ThemeBaseBoxShadowsCSS, ThemeBaseColorsCSS, ThemeBaseFontFamiliesCSS, ThemeBaseVariablesCSS, ThemeBaseZIndexCSS, ThemeSkinConfig } from '../types';

const baseColorsCSS: ThemeBaseColorsCSS = {
  '--base-color-primary': '#1EB763',
  '--base-color-hover': '#11994E',
  '--base-color-pressed': '#00904A',
  '--base-color-secondary': '#FFCF1B',
  '--base-color-success': '#1EB763',
  '--base-color-error': '#FF5252',
  '--base-color-dark-error': '#E64242',
  '--base-color-warning': '#FFF2D1',
  '--base-color-dark': '#2C312C',
  '--base-color-gray-600': '#6A6A6A',
  '--base-color-gray-500': '#8F8F8F',
  '--base-color-gray-400': '#B4B4B4',
  '--base-color-gray-300': '#D4D4D4',
  '--base-color-gray-200': '#F2F2F2',
  '--base-color-gray-100': '#F5F6F7',
  '--base-color-white': '#FFF',
  '--base-color-black': '#000',
  '--base-color-primary-bg': '#E9F8EF',
};

const baseBorderRadiiCSS: ThemeBaseBorderRadiiCSS = {
  '--base-border-radius-1': '3px',
  '--base-border-radius-2': '4px',
};

const baseBorderWidthAndStyleCSS: ThemeBaseBorderWidthAndStyleCSS = {
  '--base-border-1': '1px solid',
  '--base-border-2': '2px solid',
  '--base-border-3': '1px dotted',
  '--base-border-4': '2px dotted',
};

const baseFontFamiliesCSS: ThemeBaseFontFamiliesCSS = {
  '--base-font-family-1': '"Mulish", Verdana, Arial, sans-serif',
  '--base-font-family-2': '"Twentytwelve Sans G", Verdana, Arial, sans-serif',
};

const baseBoxShadowsCSS: ThemeBaseBoxShadowsCSS = {
  '--base-box-shadow-elevation-1': '0 2px 8px rgba(0, 0, 0, 0.1)',
  '--base-box-shadow-elevation-2': '0 3px 16px rgba(0, 0, 0, 0.08)',
  '--base-box-shadow-elevation-3': '0 18px 23px rgba(0, 0, 0, 0.07)',
};

const baseZIndexCSS: ThemeBaseZIndexCSS = {
  '--base-zIndex-header': '8',
  '--base-zIndex-modalOverlay': '10',
  '--base-zIndex-zendesk': '9',
};

export const baseVariablesCSS: ThemeBaseVariablesCSS = {
  ...baseColorsCSS,
  ...baseBorderWidthAndStyleCSS,
  ...baseBorderRadiiCSS,
  ...baseFontFamiliesCSS,
  ...baseBoxShadowsCSS,
  ...baseZIndexCSS,
};

export const baseTheme: Theme = {
  base: {
    color: {
      primary: 'var(--base-color-primary)',
      secondary: 'var(--base-color-secondary)',
      pressed: 'var(--base-color-pressed)',
      success: 'var(--base-color-success)',
      error: 'var(--base-color-error)',
      darkError: 'var(--base-color-dark-error)',
      warning: 'var(--base-color-warning)',
      dark: 'var(--base-color-dark)',
      gray600: 'var(--base-color-gray-600)',
      gray500: 'var(--base-color-gray-500)',
      gray400: 'var(--base-color-gray-400)',
      gray300: 'var(--base-color-gray-300)',
      gray200: 'var(--base-color-gray-200)',
      gray100: 'var(--base-color-gray-100)',
      primaryBg: 'var(--base-color-primary-bg)',
      white: 'var(--base-color-white)',
      black: 'var(--base-color-black)',
      transparent: 'transparent',
    },

    space: {
      none: '0',
      v1: '4px',
      v2: '8px',
      v3: '12px',
      v4: '16px',
      v5: '20px',
      v6: '24px',
      v7: '28px',
      v8: '32px',
      v9: '36px',
      v10: '40px',
    },
    border: {
      none: 'none',
      v1: 'var(--base-border-1)',
      v2: 'var(--base-border-2)',
      v3: 'var(--base-border-3)',
      v4: 'var(--base-border-4)',
    },
    borderRadii: {
      'none': '0',
      'v1': 'var(--base-border-radius-1)',
      'v2': 'var(--base-border-radius-2)',
    },
    boxShadow: {
      'none': 'none',
      'v1': 'var(--base-box-shadow-elevation-1)',
      'v2': 'var(--base-box-shadow-elevation-2)',
      'v3': 'var(--base-box-shadow-elevation-3)',
    },
    fontFamily: {
      normal: 'var(--base-font-family-1)',
      accented: 'var(--base-font-family-2)',
    },
    zIndex: {
      header: 'var(--base-zIndex-header)',
      modalOverlay: 'var(--base-zIndex-modalOverlay)',
      zendesk: 'var(--base-zIndex-zendesk)',
    },
  },

  panel: {
    borderRadius: 'var(--base-border-radius-1)',
  },

  typography: {
    color: 'var(--base-color-dark)',
    fontFamily: 'var(--base-font-family-1)',
    fontFamilyAccented: 'var(--base-font-family-2)',
  },

  button: {
    primary: {
      height: '40px',

      fontSize: '14px',
      fontFamily: 'normal',
      fontWeight: '600',

      color: 'var(--base-color-white)',
      colorHover: 'var(--base-color-white)',
      colorActive: 'var(--base-color-white)',
      colorDisabled: 'var(--base-color-gray-600)',

      bgColor: 'var(--base-color-primary)',
      bgColorHover: 'var(--base-color-hover)',
      bgColorActive: 'var(--base-color-pressed)',
      bgColorDisabled: 'var(--base-color-gray-200)',

      bdWidth: '0',
      bdRadius: '0',
      bdColor: 'transparent',
      bdColorHover: 'transparent',
      bdColorActive: 'transparent',
      bdColorDisabled: 'transparent',
    },
    secondary: {
      height: '40px',

      fontSize: '14px',
      fontFamily: 'normal',
      fontWeight: '600',

      color: 'var(--base-color-primary)',
      colorHover: 'var(--base-color-white)',
      colorActive: 'var(--base-color-white)',
      colorDisabled: 'var(--base-color-gray-500)',

      bgColor: 'transparent',
      bgColorHover: 'var(--base-color-primary)',
      bgColorActive: 'var(--base-color-pressed)',
      bgColorDisabled: 'transparent',

      bdWidth: '2px',
      bdRadius: '0',
      bdColor: 'var(--base-color-primary)',
      bdColorHover: 'var(--base-color-primary)',
      bdColorActive: 'var(--base-color-pressed)',
      bdColorDisabled: 'var(--base-color-gray-200)',
    },
    ghost: {
      height: '28px',

      fontSize: '14px',
      fontFamily: 'normal',
      fontWeight: '600',

      color: 'var(--base-color-primary)',
      colorHover: 'var(--base-color-hover)',
      colorActive: 'var(--base-color-pressed)',
      colorDisabled: 'var(--base-color-gray-500)',

      bgColor: 'transparent',
      bgColorHover: 'transparent',
      bgColorActive: 'transparent',
      bgColorDisabled: 'transparent',

      bdWidth: '0',
      bdRadius: '0',
      bdColor: 'transparent',
      bdColorHover: 'transparent',
      bdColorActive: 'transparent',
      bdColorDisabled: 'transparent',
    },
    notAccentedGhost: {
      height: '28px',

      fontSize: '14px',
      fontFamily: 'normal',
      fontWeight: '600',

      color: 'var(--base-color-gray-600)',
      colorHover: 'var(--base-color-primary)',
      colorActive: 'var(--base-color-pressed)',
      colorDisabled: 'var(--base-color-gray-500)',

      bgColor: 'transparent',
      bgColorHover: 'transparent',
      bgColorActive: 'transparent',
      bgColorDisabled: 'transparent',

      bdWidth: '0',
      bdRadius: '0',
      bdColor: 'transparent',
      bdColorHover: 'transparent',
      bdColorActive: 'transparent',
      bdColorDisabled: 'transparent',
    },
  },

  stylishLink: {
    accented: {
      color: 'var(--base-color-primary)',
      colorHover: 'var(--base-color-hover)',
      colorActive: 'var(--base-color-pressed)',

      textDecoration: 'none',
      textDecorationHover: 'none',
    },
    notAccented: {
      color: 'var(--base-color-dark)',
      colorHover: 'var(--base-color-primary)',
      colorActive: 'var(--base-color-pressed)',

      textDecoration: 'none',
      textDecorationHover: 'none',
    },
  },
};

const baseSkinConfig: ThemeSkinConfig = {
  css: baseVariablesCSS,
  theme: baseTheme,
};

export default baseSkinConfig;
