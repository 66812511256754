import { CURRENCY } from 'src/data/constants';
import Button from 'src/ui-kit/Button';
import Stack from 'src/ui-kit/Stack/Stack';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Typography } from 'src/ui-kit/Typography';
import getBundleInfo from 'src/utils/products/getBundleInfo';
import { Bundle, ImageUrls, Unit } from '../../../../api/types';
import { useLocalize } from '../../../../services/localize/useLocalize';
import formatWeight from '../../../../utils/products/formatWeight';
import { BadgesLocalizationsType } from '../../helpers/getBadgesLocalizations';
import { useProductPageExperiment } from '../../hooks/useProductPageExperiment';
import Badges from '../Badges/Badges';
import PriceRange from '../PricesRange/PricesRange';
import ProductImagesGallery from '../ProductImagesGallery/ProductImagesGallery';

export interface ProductMainInfoProps {
  images: ImageUrls[];
  title: string;
  minPrice: number;
  maxPrice: number;
  unit: Unit;
  weight: number | null;
  volume: number | null;
  bundle: Bundle;
  inStock: boolean;
  onButtonClick(): void;
  badges: BadgesLocalizationsType;
}

const ProductMainInfo = (props: ProductMainInfoProps) => {
  const {
    title,
    minPrice,
    maxPrice,
    unit,
    weight,
    volume,
    images,
    inStock,
    bundle,
    onButtonClick,
    badges,
  } = props;

  const localize = useLocalize();

  const viewWeight = formatWeight(unit, weight, volume, localize);
  const viewBundle = getBundleInfo(bundle, localize);
  const {
    experimentIsActive,
  } = useProductPageExperiment();
  const pricesAreEqual = minPrice === maxPrice;

  const buttonInStockKey = experimentIsActive
    ? 'view_prices'
    : 'products.add.to-cart';

  return (
    <Stack
      flexDirection={{
        general: 'row',
        phone: 'column',
      }}
      sbs={{
        maxWidth: '936px',
      }}
    >
      <StylishBox
        sbs={{
          m: {
            general: '0 40px 0 0',
            tablet: '0 16px 0 0',
            phone: '0 0 40px 0',
          },
          position: 'relative',
        }}
      >
        <ProductImagesGallery
          productTitle={title}
          gallery={images}
          onImageClick={() => { }}
          withHalfOpacity={false}
        />
        <Badges {...badges} withOpacity={!inStock} />
      </StylishBox>

      <div>
        <Typography
          element='h1'
          variant={{
            general: 'h4',
            phone: 'text1',
          }}

          sbs={{
            mb: {
              general: '16px',
              phone: '8px',
            },
          }}
        >
          {title}
        </Typography>

        <Typography
          variant='text6'
          color='gray500'
          sbs={{
            mb: {
              general: '20px',
              phone: '12px',
            },
          }}
        >
          {viewWeight}
          {viewBundle}
        </Typography>

        <div>
          <Typography
            variant='text6'
            color='gray500'
            element='span'
            sbs={{
              mr: '8px',
            }}
          >
            {localize(pricesAreEqual ? 'products.price' : 'products.prices')}
          </Typography>
          <PriceRange startPrice={minPrice} endPrice={maxPrice} currency={CURRENCY} />
        </div>

        <StylishBox
          sbs={{
            maxWidth: {
              desktop: '344px',
              tablet: '228px',
            },
            mt: '16px',
          }}
        >
          <Button
            onClick={onButtonClick}
            variant='primary'
            dataMarker='to cart'
            disabled={!inStock}
          >
            {localize(inStock ? buttonInStockKey : 'out_of_stock')}
          </Button>
        </StylishBox>
      </div>
    </Stack >
  );
};

export default ProductMainInfo;
