import { useEffect, useMemo, useState } from "react";
import { getProductDetailsData } from "src/api/getProductDetailsData";
import { useChains } from "src/api/swr";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import useUserDelivery from "src/services/userDelivery/useUserDelivery";
import { requestPostScheduleDelivery } from "../ajax/requestPostScheduleDelivery";
import { getRetailInfoListsForProduct } from "../helpers/getRetailInfoListsForProduct";
import getRetailInfoQueryStringForLink from "../helpers/getRetailInfoQueryStringForLink";
import { RetailInfoLists } from "../types";
import useCartItemsCounts from "./useCartItemsCounts";
import useStoresByUserDelivery from "./useStoresByUserDelivery";
import { getNPSettlementIdFromUserDelivery } from "src/utils/delivery/novaPoshtaHelpers";

const useProductRetailInfoLists = (ean: string) => {
  const language = useLang();
  const localize = useLocalize();
  const { stores, storesAreFetching } = useStoresByUserDelivery();
  const { userDelivery, userDeliveryIsReady } = useUserDelivery();
  const { chains: retailChains } = useChains({ language });
  const storeIds = stores.map(s => s.id).join(',');
  //@todo: remove in https://zakaz.atlassian.net/browse/SHWEB-9624
  const { cartItemsCounts, cartItemsCountsAreFetching } = useCartItemsCounts();
  const [isFetching, setIsFetching] = useState(true);
  const [retailInfoLists, setRetailInfoLists] = useState<RetailInfoLists>({ available: [], unavailable: [] });

  const deliveryType = userDelivery?.type || null;
  const settlementId = getNPSettlementIdFromUserDelivery(userDelivery);

  const deliveryAsQueryStringForLink = useMemo(() => {
    return getRetailInfoQueryStringForLink(userDelivery);
  }, [userDelivery]);

  const dataArePrepared = userDeliveryIsReady && !storesAreFetching && retailChains.length > 0 && !cartItemsCountsAreFetching;

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      //@todo: change in https://zakaz.atlassian.net/browse/SHWEB-9624
      const productDetails = await getProductDetailsData({ storeIds, ean, deliveryType, language });
      const scheduleDeliveryItems = deliveryType
        ? await requestPostScheduleDelivery({ storeIds, ean, deliveryType, settlementId })
        : [];

      const specificRouteForLink = productDetails?.product
        ? `products/${productDetails.product.slug}--${productDetails.product.ean}`
        : '';

      const retailLists = getRetailInfoListsForProduct({
        productChains: productDetails?.chains || [],
        specificRouteForLink,
        deliveryType,
        deliveryAsQueryStringForLink,
        cartItemsCounts,
        scheduleDeliveryItems,
        retailChains,
        language,
        localize,
      });

      setRetailInfoLists(retailLists);
      setIsFetching(false);
    };

    if (dataArePrepared) {
      fetchData();
    }

    //cartItemsCounts in deps calls redundant requests
    //@todo: remove in https://zakaz.atlassian.net/browse/SHWEB-9624
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeIds, ean, language, deliveryType, deliveryAsQueryStringForLink, retailChains, localize, dataArePrepared, settlementId]);

  const listsAreEmpty = retailInfoLists.available.length === 0 && retailInfoLists.unavailable.length === 0;

  return {
    retailListsAreLoading: isFetching,
    skeletonIsDisplayed: listsAreEmpty,
    retailInfoLists,
  };
};

export default useProductRetailInfoLists;
